<template>
  <div class="container">
    <div class="user-card">
      <van-image
        class="image"
        round
        width="58px"
        height="58px"
        :src="
          userInfo.headImgUrl
            ? userInfo.headImgUrl
            : 'https://img01.yzcdn.cn/vant/cat.jpeg'
        "
      />
      <div class="center-card">
        <div class="center-card-tel">{{ userInfo.tel }}</div>
        <div class="center-card-realName">
          {{ userInfo.realStatus ? '已实名' : '未实名' }}
        </div>
        <div class="center-card-bind">已绑定手机号：{{ userInfo.tel }}</div>
      </div>
      <div class="set" @click="toSetting">设置 <van-icon name="arrow" /></div>
    </div>
    <div class="box-card">
      <div class="box-card-1">
        <div class="title">我的时长</div>
        <div class="button" @click="$router.push({ name: 'timeRecord' })">
          查看记录 <img src="../../assets/icon/right-icon.png" alt="" />
        </div>
      </div>
      <div class="line"></div>
      <div class="box-card-2">
        <div class="title2">剩余可用时长</div>
        <div class="title">
          {{
            userInfo.usableSecond ? calcHourMin(userInfo.usableSecond) : '0分钟'
          }}
        </div>
        <div class="button" @click="toRemainTime">
          详情 <img src="../../assets/icon/right-icon.png" alt="" />
        </div>
      </div>
    </div>
    <div class="cell" @click="onRechargeClick">
      <img src="../../assets/icon/buy-icon.png" alt="" />
      <div class="title">购买</div>
      <van-icon size="18px" color="#aaaaaa" name="arrow" />
    </div>
    <div class="cell" @click="onExchangeClick">
      <img src="../../assets/icon/exchange-icon.png" alt="" />
      <div class="title">兑换</div>
      <van-icon size="18px" color="#aaaaaa" name="arrow" />
    </div>
  </div>
</template>

<script>
import { calcHourMin } from '@/utils/index.js'
export default {
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  mounted() {
    this.$store.dispatch('getUserInfo')
  },
  methods: {
    calcHourMin,
    toSetting() {
      this.$router.push({ name: 'Setting' })
    },
    toRemainTime() {
      this.$router.push({ name: 'RemianTime' })
    },
    onRechargeClick() {
      if (this.userInfo.idCard && this.userInfo.realName) {
        this.$router.push({ name: 'recharge' })
      } else {
        this.$toast('请先完成实名认证：\n“设置”->“实名认证”')
      }
    },
    onExchangeClick() {
      this.$router.push({ name: 'exchange' })
    }
  }
}
</script>

<style lang="less" scoped>
.user-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 18px 15px;
  .image {
    border: 2px solid #df8e1e;
  }
  .center-card {
    margin-left: 18px;
    flex: 1;
    .center-card-tel {
      color: @text-color-1;
      font-size: 18px;
      line-height: 25px;
      font-weight: 500;
    }
    .center-card-realName {
      font-size: 13px;
      color: @text-color-2;
      line-height: 18px;
      margin-top: 6px;
    }
    .center-card-bind {
      font-size: 13px;
      color: @text-color-2;
      line-height: 18px;
      margin-top: 4px;
    }
  }
  .set {
    color: @text-color-1;
    opacity: 0.6;
    font-size: 14px;
    line-height: 20px;
  }
}
.box-card {
  position: relative;
  display: flex;
  width: 345px;
  height: 150px;
  background: url('../../assets/images/my-boxbg.png') no-repeat center;
  background-size: cover;
  margin: 10px auto;
  .box-card-1 {
    width: 49%;
    padding-top: 30px;
    text-align: center;
    .title {
      color: @text-color-1;
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
    }
    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 88px;
      height: 24px;
      border-radius: 14px;
      border: 1px solid #cb7e0e;
      margin: 23px auto;
      color: #cb7e0e;
      font-size: 13px;
      line-height: 28px;
      img {
        width: 12px;
        object-fit: cover;
        margin-left: 4px;
      }
    }
  }
  .box-card-2 {
    width: 50%;
    padding-top: 21px;
    text-align: center;
    .title2 {
      font-size: 13px;
      color: @text-color-1;
      opacity: 0.6;
      line-height: 18px;
    }
    .title {
      margin-top: 5px;
      font-size: 18px;
      font-weight: 500;
      color: @text-color-1;
      line-height: 25px;
    }
    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 62px;
      height: 24px;
      border-radius: 14px;
      border: 1px solid #cb7e0e;
      margin: 14px auto;
      color: #cb7e0e;
      font-size: 13px;
      line-height: 28px;
      img {
        width: 12px;
        object-fit: cover;
        margin-left: 4px;
      }
    }
  }
  .line {
    position: absolute;
    left: 50%;
    top: 23px;
    width: 1px;
    height: 103px;
    border: 1px solid;
    transform: scaleX(0.4) translate(50%);
    border-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.04),
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.04)
      )
      1 1;
  }
}
.cell {
  box-sizing: border-box;
  width: 100%;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  img {
    width: 16px;
    object-fit: cover;
  }
  .title {
    margin-left: 12px;
    flex: 1;
    font-size: 14px;
    color: @text-color-1;
    line-height: 20px;
  }
}
</style>
